import { Component, OnInit, Input } from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-feather-icons',
  templateUrl: './feather-icons.component.html',
  styleUrls: ['./feather-icons.component.scss']
})
export class FeatherIconsComponent implements OnInit {

  @Input('icon') public icon = 'home';
  iShow = 0;
  constructor() {
    // this.icon = this.icon ?? 'alert-circle';
    this.iShow = 0
   }

  ngOnInit() {
    setTimeout(() => {
      feather.replace();
      this.iShow = 1;
    },10);
  }

}
