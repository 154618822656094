import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'user',
    loadChildren: () => import('../../pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'overhead-type',
    loadChildren: () => import('../../pages/overhead-type/overhead-type.module').then(m => m.OverheadTypeModule)
  },
  {
    path: 'project',
    loadChildren: () => import('../../pages/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('../../pages/purchase/purchase.module').then(m => m.PurchaseModule)
  },
 
];
