// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //serverUrl: 'http://127.0.0.1:8000/api/',
  //serverUrl:'http://localhost:8001/api/', 
  serverUrl: 'https://edoc.powidea.com/api/',
  firebase: {
    apiKey: "AIzaSyCVlmmYtwf1Js1SzXeiKrCsGpgMkO0tvHc",
    authDomain: "localhost",
    databaseURL: "Your Database Url",
    projectId: "edoc-8243b",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
