import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    private token: string;

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.token = localStorage.getItem('access_token');
        request = request
            .clone({
                url: request.url,
                headers: request.headers
                    .append('Accept', 'application/json ')
                    .append('Authorization', 'Bearer ' + decodeURIComponent(this.token))
            });

        return next.handle(request);
    }

}
