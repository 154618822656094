import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


/**
 * Adds a default error handler to all requests.
 */

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public toster: ToastrService,
    ) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
    }
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
          this.router.navigate(['/login']);
          break;
        case 403:
          this.toster.error('Forbidden');
          break;
        case 500:
          // console.log('response.error', response.error);
          // #JobComment, Error ยาวไป
          // if (response.error.ResponseException) {
          //   str += '<p><b>Error Details</b>: ' + response.error.ResponseException.Details + '<p>';
          //   str += '<p><b>Exception Message</b>: ' + response.error.ResponseException.ExceptionMessage + '<p>';
          // 
          const errorMessage = (response.error.ResponseException ?
            response.error.ResponseException.ExceptionMessage
            : response.error.Message);
          Swal({
            title: 'Error!',
            text: errorMessage,
            type: 'error',
            confirmButtonText: 'Close'
          });

          break;
        default:
          break;
      }
    }
    throw response;
  }

}
